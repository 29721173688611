<template>
<a href="" @click="logout">Logout</a>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Logout',
  props: {},
  methods: {
	logout(){ axios.post(this.$api, {logout:'' }).then().catch((e) => window.console.log(e.message)) }
  }
}
</script>

<style scoped>
a{
	padding: 1ex;
	font-size: 1.5rem;
	text-decoration: none;
	position: fixed;
	top: 0.1rem;
	left: 0.1rem;
}
</style>
