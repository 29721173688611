<template>
  <div v-if="id">
    <h2 v-if="id.title">{{ id.title }}</h2>
    <div class="buildDetail">
      <span v-for="t in titles" :key="t">
        <strong>{{ t }}</strong><br>{{detail[t]}}
      </span>
    </div>
    <!-- <table>
    <thead><tr>
    <th v-for="t in titles" :key="t">{{t}}</th>
    </tr></thead>
    <tbody><tr>
    <td v-for="t in titles" :key="t">{{detail[t]}}</td>
    </tr></tbody>
    </table> -->
    <dl>
     <div v-for="g in dirs" :key="g">
      <dt>{{g}}</dt>
      <dd v-for="f in files[g]" :key="g+f"><a v-if="typeof f==='string'" download :href="path+g+'/'+f">{{f}}</a></dd>
     </div>
    </dl>
    <h2 v-if="id.title">{{ id.title }}</h2>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'BuildingInfo',
  props: { id: Object, archive: Boolean },
  computed:{
    // fileNames: function(v){ return files[v].filter(a => typeof a==='string') }
  },
  methods:{
  DeBounce(fn,delay){
    clearTimeout(this.timer);
    this.timer = setTimeout(fn,delay);
  },
	getData(){
	//const login = 'index.html'; //url = 'getdata.php' 'BuildFile.json' 'BuildDetail.json' this.$api
		const getFile = () => axios.post(this.$api,{ getdata: '', getbuildingfile: this.id.reference, org: this.id.org, archive: this.archive } )
		const getDetail = () =>  axios.post(this.$api,{ getdata: '', getbuilding: this.id.id, archive: this.archive } )
		if (this.id){
			axios.all([getFile(),getDetail()])
				.then(axios.spread((f,d)=> /*f.data.loggedout ? window.location.href=this.$login :*/
					(this.files=f.data.length ? f.data[1] : {},
          // window.console.log(this.files),
          Object.keys(this.files).forEach((dir) => { //Each array is subdirectory.
            Object.keys(this.files[dir]).forEach((itm)=>{
              if(dir==='archive') {
                this.files[dir+'/'+itm]=this.files[dir][itm];
                delete this.files[dir][itm];
              }
            })
            if(dir==='archive') delete this.files[dir];
          }),
          this.dirs=Object.keys(this.files),
          // window.console.log(this.files),
					this.path=f.data.length ? 'survey/'+f.data[0] : [],
					this.detail=d.data.length ? d.data[0] : [],
					this.titles=Object.keys(this.detail).filter(k => k!=='id' && this.detail[k]!==null && this.detail[k]!=='')
					) )
				).catch( (e) => window.console.log(e) ) /* window.location.href=this.$login */
		}
	}
  },
/*  computed: { Not needed, may be wrong to use, may help performance.
	fpath: function () {return this.path +  }
  },*/
  data() { return { detail: Object, titles: Array, path: String, files: Object, dirs: Array, timer: Number } },
  watch: { id: function(){ this.DeBounce(this.getData,500) }, archive: function(){ this.getData() } },
/*  filters: {  Use style sheet to Capitalize.
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }*/
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.buildDetail{ display: flex; flex-wrap: wrap; gap: 0.1rem; justify-content: center; }
.buildDetail > * { border: solid 1px; border-radius: 0.5rem; padding: 0.3rem; }
.buildDetail strong {text-transform: capitalize;}
/* table{border-style: double; margin-left: auto; margin-right: auto; width: 100%; }
th, td,  */
dt{ border: solid 1px; text-transform: capitalize; padding: 0 3px; float: left; background: #5f9be3; color: #fff; font-weight: bold; }
dl div { border: solid 2px; margin: 1ex auto; text-align: center; width: 50%; min-width: 27rem; }
h2{ text-align: center; }
ul{ list-style-type: none; padding: 0; }
li{ display: inline-block; margin: 0 10px; }
a{ color: #42b983; }
/* table, th, td,  */
dl div, dt{ border-radius: 0.3rem; }
</style>
