import { createApp } from 'vue'
import App from './App.vue'
// npm run serve  npm run build

// VUE 2 code
// Vue.prototype.$api=process.env.NODE_ENV==='production'?'php/server.php':'http://survey.localhost/php/server.php'
// Vue.prototype.$login = 'login.php' 

// new Vue({
//   render: h => h(App),
// }).$mount('#app')

// VUE 3 code
const app = createApp(App)
app.config.globalProperties.$api=process.env.NODE_ENV==='production'?'php/server.php':'http://survey.localhost/php/server.php'
app.config.globalProperties.$login = 'login.php'
app.mount('#app')
