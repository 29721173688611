<template>
	<div id="app">
		<header>
			<Logout v-show="userData.token" />
			<img src="img/logo-sm.png" width="253" height="87" alt="GrandEnviro Logo" />
			<h1>{{userData.orgName}} Hazardous Management System</h1>
			<!--<HeadSection org="" msg="userData.orgName + ' Hazardous Management System'"/>-->
			<div>Welcome to the {{this.userData.orgName}} building materials tracking system.</div>
		</header>
		<div class="loginform" v-show="!userData.token">
			<form name="login" @submit.prevent>
			<label><span>Name:</span><input type="text" v-model="userData.uname" /></label>
			<label><span>Password:</span><input type="password" v-model="userData.pswd" /></label>
			<label><span></span><input type="submit" :disabled="loggingin" value="Login" @click="login()" /></label>
			</form>
		</div>
		<div v-show="loginerror">Login Failed.</div>
		<hr v-if="userData.token" />
		<div v-if="userData.token" class="main-cont">
			<BuildingForm @archive-setting="v => this.archive = v" />
			<hr>
			<AnnualReport :archive="this.archive" />
		</div>
		<!-- <div title="Async Code">
			<input type="button" @click="alarm('hey there',2*1000).then((msg)=>alarmMsg(msg)).catch((msg)=>alarmMsg(msg))" value="Alarm then" />
			<input type="button" @click="alarmAsync('AAAA Name',1*1000)" value="Alarm async" />
		</div> -->
  </div>
</template>

<script>
//import HeadSection from './components/HeadSection.vue'
import Logout from './components/Logout.vue'
import AnnualReport from './components/AnnualReport.vue'
import BuildingForm from './components/BuildingForm.vue'
import axios from 'axios'

export default {
  name: 'app',
  components: {
//    HeadSection,
    Logout,
    AnnualReport,
    BuildingForm
  },
  data() {
	return { userData:{uname: String, pswd: String, token: String, org: String, orgName: String}, loginerror: String, archive: false, loggingin: false }
  },
  created() {
	//this.userData.uname = process.env.NODE_ENV==='production'?'':'norfolk'
	//this.userData.pswd = process.env.NODE_ENV==='production'?'':'norfolk'
	this.userData.uname = process.env.NODE_ENV==='production'?'':'grandenviro'
	this.userData.pswd = process.env.NODE_ENV==='production'?'':'dinogrande'
	//this.userData.uname = process.env.NODE_ENV==='production'?'':'demo'
	//this.userData.pswd = process.env.NODE_ENV==='production'?'':'demo'
	// this.userData.uname = process.env.NODE_ENV==='production'?'':'wilson'
	// this.userData.pswd = process.env.NODE_ENV==='production'?'':'blanchard'
	this.userData.token = ''
	this.userData.orgName = ''
	this.userData.org = ''
	this.loginerror = ''
  //if(process.env.NODE_ENV==='development') axios.defaults.withCredentials = true
	axios.defaults.withCredentials = process.env.NODE_ENV==='development'
  },
  methods:{
		alarm(person, delay){
			return new Promise((resolve) => {
				if(delay<0)	throw new Error('Delay must be positive.');
				setTimeout(()=> resolve(`Wake up ${person} it has been ${delay/1000}s`),delay);
			})
		},
		async alarmAsync(name,delay){
			try{
				const mesg = await this.alarm(name,delay);
				alert(mesg);
			}catch(e){alert(e);}
		},
		alarmMsg(m){alert(m)},
    login(){
			// DeBounce: After an event stops happening run a function. eg Update display or do search after user stops typing.
			// Throttling: Prevent event from running a function again until given amount of time has passed.
			if(!this.loggingin){
				this.loggingin = true //Prevent login attempt while one is currently happening.
	    //   this.loginerror = false
				axios.post(this.$api, this.userData).then(r => {
					if (r.data.token.length){
						this.userData.token = r.data.token
						this.userData.pswd = ''
						this.userData.org = r.data.org
						this.userData.orgName = r.data.orgName
					}
					if (r.data.denied) this.loginerror = true
				}).catch( (e) => {
					this.loginerror = true
					window.console.log(e)
					// window.location.href=this.$login
				}).finally(this.loggingin = false)
	    }
		}
  }
}
</script>

<style>
*, ::before, ::after {box-sizing: border-box;}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
header{text-align: center;}
hr{width: 80%;}
.loginform form { display: table; margin-left: auto; margin-right: auto; }
.loginform label { display: table-row; }
.loginform span, .loginform input { display: table-cell; margin: 0.2rem;}
.loginform span{text-align: right;}
/* .main-cont{display: flex; flex-wrap: wrap; gap: 2rem; justify-content: center; flex-grow: 1 0 73rem;}
.main-cont>:first-child{max-width: 73rem;} */
.main-cont{text-align: center;}
.main-cont>:last-child{border: solid 1px; border-radius: 1rem; padding: 0 0.5rem; height: min-content;}
@media screen and (min-width: 73rem) {
	.main-cont hr {display: none;}
	.main-cont {display: grid; gap: 1rem; grid-template-columns: 3fr 1fr;}
	.main-cont>:last-child{text-align: left;}
}
</style>
