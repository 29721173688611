<template>
<div>
<form @submit.prevent>
<div v-if="orgs.length>1">
<label>Organization<br><select size="10" v-model="org">
	<option value=""></option>
	<option v-for="o in orgs" :key=o.org :value=o.org>{{o.orgName}}</option>
</select>
</label></div>
<div v-if="cities.length>1">
 <label>City<br><select class="classC" size="10" v-model="city">
	<option value=""></option>
	<!--<option v-for="c in cities" :key=c.city :value=c.city>{{c.city}}</option>-->
	<option v-for="c in cities" :key=c :value=c>{{c}}</option>
  </select>
 </label>
</div>
<div>
<label>Building<br><select classN="building" size="10" v-model="building">
	<option value=""></option>
	<option v-for="b in buildings" :key=b.id :value=b.id>{{b.title}}</option>
</select>
</label></div>
<br><br>
<div>
	<label class="rpt-opt">Report Available
	<span>
	<label>yes<input type="radio" value="yes" v-model="surveyed"></label>
	<label>no<input type="radio" value="no" v-model="surveyed"></label>
	<label>all<input type="radio" value="all" defaultChecked v-model="surveyed"></label>
	</span>
	</label>
	<label class="incArc">Include Archived<input type="checkbox" v-model="archive"></label><br>
</div>
</form>
<BuildingInfo :archive="this.archive" :id="this.buildings && this.buildings.length ? this.buildings.filter(b => b.id === this.building)[0]:{}"/>
</div>
</template>

<script>
import axios from 'axios'
import BuildingInfo from '../components/BuildingInfo.vue'

export default {
  name: 'BuildingForm',
  components: {
    BuildingInfo
  },
  emits: ['archive-setting'],
  data() {
	return { cityList: Array, buildList: Array, city: String, building: Number, surveyed: String, archive: Boolean, orgs: Array, org: String }
  },
  computed:{
    buildings(){
			return this.buildList.filter(c =>
				(!this.city || c.city===this.city)
				&& (this.surveyed==='all' || c.surveyed === this.surveyed)
				&& (!this.archive || c.demolished === null)
				&& (!this.org || c.org === this.org)
			)
		// let b = this.buildList;
		// if (this.city!=='') b = b.filter( c => c.city === this.city )
		// if (this.surveyed!=='all') b = b.filter( c => c.surveyed === this.surveyed )
		// if (!this.archive) b = b.filter( c => c.demolished === null )
		// if (this.org!=='') b = b.filter( o => o.org === this.org )
    //   return b;
    },
    cities(){
      const c = this.org!=='' ? this.buildList.filter(z => z.org === this.org) : this.cityList;
      // if (this.org!=='') c = this.buildList.filter(z => z.org === this.org);
      return [ ...new Set(c.map(z => z.city)) ].sort();
    },
  },
  methods: {
	getData(){
		//const login = 'index.html' //window.location.href='index.html' 'Cities.json' 'Buildings.json'
		const xCities = () => axios.post(this.$api,{ getopt:'city' } );
		const xBuiding = () => axios.post(this.$api,{ getopt:'building', archive: this.archive } );
		const xOrganizations = () => axios.post(this.$api,{ getopt:'organization' } );
		axios.all([xCities(),xBuiding(),xOrganizations()]).then( axios.spread( (c, b, o) =>{
			// this.cities = c.data;
			this.cityList = c.data;
			this.buildList = b.data;
			this.orgs = o.data;
		})).catch( e => {
				window.console.log(e)
				// window.location.href=this.$login
			} );
	},
  },
  watch: {
    archive: function (v) {this.$emit('archive-setting',v);}
  },
  created() {
	this.archive = false
	this.surveyed = 'all'
	this.city = ''
	this.building = 0
	this.buildList = []
	this.org = ''
	this.cityList = []
  },
  mounted() {
	this.getData();
  }
}
</script>

<style scoped>
form div{
	text-align: center;
	display: inline-block;
	padding: 0 1ex;
	vertical-align: middle;
}
select{ border-radius: 1ex; }
select[classN=building]{ width: 24em; }
.rpt-opt{padding-right: 1em;}
.rpt-opt span{border-style: double; border-radius: 15px;}
.rpt-opt span label{padding: 0 1ex;}
label, .incArc{display: inline-block;}
</style>
