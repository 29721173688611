<template>
<div>
  <h2>Annual Reports</h2>
   <dl v-if="org.length>1">
    <div class="org" v-for="o in org" :key="o.titles">
     <dt>{{o.orgName}}</dt>
     <dd v-show="archive" v-for="t in Object.keys(o.annualRpt)" :key="t"><a download :href="o.filepath+'/'+o.annualRpt[t]">{{t}}</a></dd>
     <dd v-show="!archive" v-for="t in o.current" :key="t+'b'"><a download :href="o.filepath+'/'+o.annualRpt[t]">{{t}}</a></dd>
    </div>
   </dl>
   <!--<span v-if="org.length===1">
		<a v-show="archive" v-for="t in Object.keys(org[0].titles)" :key="t" :href="org.filepath+'/'+org.annualRpt[t]" target="_blank">{{t}}<br/></a>
		<a v-show="!archive" v-for="t in org[0].current" :key="t+'c'" :href="org.filepath+'/'+org.annualRpt[t]" target="_blank">{{t}}<br/></a>
   </span>-->
	<span v-else>
		<a v-show="archive" download v-for="t in org.titles" :key="t" :href="org.filepath+'/'+org.annualRpt[t]">{{t}}<br/></a>
		<a v-show="!archive" download v-for="t in org.current" :key="t+'c'" :href="org.filepath+'/'+org.annualRpt[t]">{{t}}<br/></a>
	</span>
</div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AnnualReport',
  data() {
	return { org: Array }
  },
  props: { archive: Boolean },
  methods: {
	getAnnualReports(){
		axios.post(this.$api,{ getmeta:'summrpt' } ).then(r => {
       this.org = r.data
       if(this.org.length===1){
         this.org = this.org[0]
         if(this.org.annualRpt) this.org.titles = Object.keys(this.org.annualRpt)
       }
		} ).catch( (e) => {
				window.console.log(e)
				// window.location.href=this.$login
		})
	}
  },
  mounted() {
	this.getAnnualReports();
  }
}
</script>

<style scoped>
a{ font-size: 1.5em; text-align: center; }
/* dl{ float: right; clear: both; } */
dt{ font-weight: bold; text-decoration: underline; }
dd{ margin: 0; padding: 0 0 0.5em 0; }
</style>
